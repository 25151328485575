<template>
	<b-modal v-model="dialogState" id="notification-incident-details-modal" ref="notification-incident-details-modal"
		title="Incident Details" ok-only>
		<div v-if="Object.keys(getIncidentDetails).length === 0" :style="{ height: '100px' }">
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
		</div>
		<div v-else>
			<!-- Primary Information -->
			<b-row>
				<b-col md="12" class="md-left-text-sm-right">
					<b>PRIMARY INFORMATION</b>
				</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Summary:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					getIncidentDetails.summary
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Type:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					getIncidentDetails.incidentType.name
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Remarks:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					getIncidentDetails.remarks
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Location:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					getCoordinates(getIncidentDetails)
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Source:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{ getIncidentDetails.source.company }} -
					{{ getIncidentDetails.source.storageLocation }}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Destination:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{ getIncidentDetails.destination.company }} -
					{{ getIncidentDetails.destination.storageLocation }}</b-col>
			</b-row>

			<!-- Transportation -->
			<b-row class="mt-4">
				<b-col md="12" class="md-left-text-sm-right">
					<b>TRANSPORTATION</b>
				</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Plate No/CR/OR:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					getIncidentDetails.transportation.plateNo
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Company:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					getIncidentDetails.transportation.company
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Driver:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					getIncidentDetails.driver.name
				}}</b-col>
			</b-row>
			<b-row no-gutters class="mt-2">
				<b-col cols="4" class="text-sm-right pr-3">
					<b>Assistants:</b>
				</b-col>
				<b-col cols="8" class="text-sm-left">{{
					!getIncidentDetails.transportation.assistants
					? '--'
					: getIncidentDetails.transportation.assistants
				}}</b-col>
			</b-row>
		</div>
	</b-modal>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { mapGetters } from 'vuex';
import { LocationUtil } from '@/utils/locationUtil';

export default {
	name: 'notification-incident-details',
	components: {
		Loading,
	},
	data() {
		return {
			isLoading: true,
		};
	},
	computed: {
		...mapGetters('notificationIncidentDetails', [
			'getDialogState',
			'getIncidentDetails',
		]),
		dialogState: {
			get() {
				return this.getDialogState;
			},
			set() {
				this.$store.commit('notificationIncidentDetails/TOGGLE_DIALOG');
			}
		},
	},
	methods: {
		getCoordinates(details) {
			const { geoaddress, latitude, longitude } = details;

			const finalGeoaddress = geoaddress || {
				_latitude: latitude,
				_longitude: longitude
			};

			const formattedGeoaddress = LocationUtil.getGeoaddress(finalGeoaddress);
			const { latitude: lat, longitude: lng } = formattedGeoaddress;

			return `(${lat.toFixed(6)}, ${lng.toFixed(6)})`;
		},
	}
};
</script>