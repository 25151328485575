// Util
import { UserUtil } from '@/utils/userutil';

// Others
import axios from 'axios';
import config from '../config/env-constants';


export default {
    baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',

    getUsers(filterBy, view, currUserId) {
        const url = `${this.baseUrl}/getUsers`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    addUser(user, currUserId, currTimeStamp) {
        const url = `${this.baseUrl}/addUser`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            user: JSON.stringify(user)
        });
    },

    updateUser(user, currUserId, currTimeStamp) {
        // apply cleanup before saving
        user = UserUtil.cleanupFields(user);

        const url = `${this.baseUrl}/updateUser`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            user: JSON.stringify(user)
        });
    },

    resetToNewUser(user, currUserId, currTimeStamp) {
        // apply cleanup before saving
        user = UserUtil.cleanupFields(user);

        const url = `${this.baseUrl}/resetToNewUser`;
        return axios.post(url, {
            currUserId: currUserId,
            currTimeStamp: currTimeStamp,
            user: JSON.stringify(user)
        });
    },

    validatePassword(password, currUserId) {
        const url = `${this.baseUrl}/validatePassword`;
        return axios.post(url, {
            currUserId: currUserId,
            password: password
        });
    },

    validateLogin(email, password) {
        const url = `${this.baseUrl}/validateLogin`;
        return axios.post(url, {
            email: email,
            password: password,
            requestSource: config.requestSource.CMS
        });
    }
}