<template>
	<div>
		<HeaderDropdown no-caret left>
			<template slot="header">
				<b-nav-item :class="notifAnimator">
					<span class="bell">
						<i class="icon-bell"></i>
					</span>
					<b-badge v-if="getUnreadNotificationsLength" pill variant="danger">{{
						getUnreadNotificationsLength
					}}</b-badge>
				</b-nav-item>
			</template>
			<template slot="dropdown">
				<div :style="{ display: 'block' }">
					<b-dropdown-header
						tag="vue-custom-scrollbar"
						class="text-center bg-white"
					>
						<strong v-if="getUnreadNotificationsLength === 0"
							>Notifications</strong
						>
						<strong v-else
							>You have {{ getUnreadNotificationsLength }} unread
							message(s)</strong
						>
					</b-dropdown-header>
					<vue-custom-scrollbar class="notif-box">
						<template v-if="notifications.length">
							<NotificationItem
								v-for="item in notifications"
								:key="item.id"
								:item="item"
							/>
						</template>
						<b-alert v-else show>
							<i class="fa fa-check-circle"></i>&nbsp; You have no messages
							today.
						</b-alert>
					</vue-custom-scrollbar>
				</div>
			</template>
		</HeaderDropdown>

		<ForceLogoutConfirmation />
		<NotificationDispatchDetails />
		<NotificationIncidentDetails />
	</div>
</template>

<script>
// Component
import NotificationItem from './NotificationItem';
import ForceLogoutConfirmation from '../../account/ForceLogoutConfirmation';
import NotificationDispatchDetails from './NotificationDispatchDetails';
import NotificationIncidentDetails from './NotificationIncidentDetails';
import vueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

// Others
import { BIconBellFill } from 'bootstrap-vue';
import { HeaderDropdown } from '@coreui/vue';
import { db, firebase } from '@/config/firebase';

export default {
	name: 'Notifications',
	components: {
		HeaderDropdown,
		NotificationItem,
		BIconBellFill,
		ForceLogoutConfirmation,
		NotificationDispatchDetails,
		NotificationIncidentDetails,
		vueCustomScrollbar
	},
	data() {
		return {
			notifications: [],
			notificationsListener: '',
			userSessionListener: '',
		};
	},
	computed: {
		getUnreadNotificationsLength() {
			return this.notifications.filter((item) => item.isViewed === false)
				.length;
		},
		notifAnimator() {
			if (this.getUnreadNotificationsLength > 0)
				return 'animated infinite tada';
		},
	},
	mounted() {
		const email = this.$store.getters['email'];
		if (email && email !== null) {
			this.fetchNotifications(email);
			this.fetchUserSession(email);
		}
	},
	methods: {
		fetchNotifications(email) {
			this.notificationsListener = db
				.collection('notifications')
				.where('recipient', '==', email)
				.orderBy('dateSent', 'desc')
				.onSnapshot(
					(doc) => {
						let notifications = [];
						doc.forEach((item) => {
							const data = item.data();
							if (
								data.rules.deviceType == 'web' ||
								data.rules.deviceType == 'hybrid'
							) {
								notifications.push({
									id: item.id,
									...data,
								});
							}
						});
						this.notifications = notifications;
					},
					() => {
						let user = JSON.stringify(firebase.auth().currentUser);
						if (user.email) {
							this.$toaster.warning(
								'Oops! Something went wrong. Please refresh the page.'
							);
						}
					}
				);
		},
		fetchUserSession(email) {
			const cachedIdToken = localStorage.getItem('token');
			if (cachedIdToken && cachedIdToken.length > 0) {
				let userSessionRef = db.collection('userSessions').doc(email);
				this.userSessionListener = userSessionRef.onSnapshot((doc) => {
					const userSession = doc.data();
					const user = firebase.auth().currentUser;
					if (
						userSession.idToken !== cachedIdToken &&
						user != null &&
						user.email === userSession.emailAddress
					) {
						this.$bvModal.show('force-logout-confirmation');
					}
				});
			}
		},
		detachUserSessionListener() {
			if (typeof this.userSessionListener === 'function') {
				this.userSessionListener();
			}
		},
		detachNotificationListener() {
			if (typeof this.notificationsListener === 'function') {
				this.notificationsListener();
			}
		},
		onClick() {
			this.$refs.dropdown.hide(true);
		},
	},
	destroyed() {
		this.detachNotificationListener();
		this.detachUserSessionListener();
	},
};
</script>

<style lang="scss" scoped>
@import '~animate.css';
.chat-box-holder {
	background-color: #f9f9fa;
	padding: 10px 10px;
	width: 400px;
	max-height: 80vh;
}
.notif-list-container {
	max-height: 500px;
}
.bell {
	color: #122c91;
}

.notif-box {
  position: relative;
  padding: 0.5em;
  height: 700px;
}

</style>
